import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
	'en': {
		spanish: 'Spanish',
		english: 'English',
		portugues: 'Portuguese',
		evaluationTitle: 'Evaluation',
		loadingEvaluation: 'Loading Evaluation',
		evaluatedUserData: 'User Data',
		name: 'Name',
		gender: 'Sex',
		email: 'Email',
		phone: 'Phone',
		region: 'Region',
		comuna: 'City',
		address: 'Address',
		birth: 'Birth Date',
		age: 'Age',
		temperature: 'Temperature',
		dificulToBreath: 'Difficulty breathing',
		cronAge: 'Chronological Age',
		height: 'Height',
		weight: 'Weight',
		alcohol: 'Alcohol',
		smoke: 'Smoke',
		years: 'Years',
		months: 'Months',
		yes: 'Yes',
		no: 'No',
		evaluatedUserClinicalData: 'User Clinical Data',
		entDisease: 'Not transmissible cronic diseases',
		noEntDisease: 'Without not transmissible cronic diseases',
		inTreatment: 'Treathed',
		noTreatment: 'Not treathed',
		autoinmuneDisease: 'Autoimmune diseases',
		noAutoinmuneDisease: 'Without autoimmune diseases',
		entryData: 'Entry Data',
		basicData: 'Basic Data',
		neckPerimeter: 'Neck Perimeter',
		enterNeckPerimeter: 'Enter Neck Perimeter',
		waistPerimeter: 'Waist Perimeter',
		hipPerimeter: 'Hip Perimeter',
		rightArmPerimeter: 'Right Arm Perimeter',
		rightLegPerimeter: 'Right Leg Perimeter',
		diastolic: 'Diastolic Pressure',
		sistolic: 'Sistolic Pressure',
		imc: 'IMC',
		fatPercent: 'Fat Percent',
		enterFatPercent: 'Enter Fat Percent',
		magraPercent: 'Lean Mass Percentage',
		imb: 'Basal metabolic rate',
		fisiologicalData: 'Fisiological Data',
		calories: 'calories',
		bodyComposition: 'Body Composition',
		fatWeight: 'Fat Weight',
		magroWeight: 'Lean Weight',
		fullWeight: 'Full Weight',
		graphFat: '% Fat',
		graphLean: '% Lean Mass',
		bodyCompositionAnalysis: 'Body Composition Analysis',
		imcAnalysis: 'IMC Analysis',
		imcIdeal: 'Ideal IMC',
		refImcRange: 'Reference IMC Range',
		veryLow: 'Very Low',
		low: 'Low',
		normal: 'Normal',
		high: 'High',
		veryHigh: 'Very High',
		moderate: 'Moderate',
		optimum: 'Óptimum',
		highModerate: 'Moderate High',
		extremelyHigh: 'Extremely high',
		to: 'To',
		from: 'From',
		waistHeightAnalysis: 'Waist/Height Analysis',
		waistHipAnalysis: 'Waist/Hip Analysis',
		waistHeightIndex: 'Waist/Height Index',
		waistHipIndex: 'Waist/Hip Index',
		referenceRange: 'Reference Range',
		analysis: 'Analysis',
		severeThinness: 'Severe Thinness',
		slightThinness: 'Slight thinness',
		normalWeight: 'Normal Weight',
		overweight: 'Overweight',
		highOverweight: 'High Overweight',
		morbidObesity: 'Morbid Obesity',
		scoring: 'Scoring',
		caloricRequirement: 'Caloric Requirement',
		minimum: 'Minimum',
		average: 'Average',
		maximum: 'Maximum',
		entryLevel: 'Entry Level',
		trainingLevel: 'Training Level',
		risk: 'Risk',
		normal: 'Normal',
		sedentary: 'Sedentary',
		veryGood: 'Very Good',
		basic: 'Basic',
		medium: 'Medium',
		advance: 'Advanced',
		historic: 'History',
		date: 'Date',
		sendEvaluation: 'Send Evaluation',
		sendEvaluationByEmail: 'Send Evaluation By Email',
		enterEmail: 'Enter Email',
		myEvaluations: 'My Evaluations',
		addEvaluation: 'Add Evaluation',
		lastName: 'Last Name',
		creationDate: 'Creation Date',
		actions: 'Actions',
		update: 'Update',
		deletedEvaluation: 'Evaluation Deleted',
		deletedEvaluationMessage: 'The evaluation has been deleted.',
		error: 'Error',
		errorMessage: 'There was an error in the operation, please try again.',
		myClients: 'My Clients',
		addClient: 'Add Client',
		newUser: 'New User',
		enterName: 'Enter Name',
		enterLastname: 'Enter Lastname',
		selectGender: 'Select Gender',
		birthDescription: 'Press the calendar icon to select a date. Age between 13 and 100 years.',
		enterPhone: 'Enter Phone',
		enterAddress: 'Enter Address',
		country: 'Country',
		selectCountry: 'Select Country',
		region: 'Region',
		selectRegion: 'Select Region',
		comuna: 'City',
		selectComuna: 'Select City',
		selectCompany: 'Select Company (Optional)',
		allFieldsRequired: 'All fields are required',
		createUser: 'Create User',
		createdUser: 'User Created',
		okCreatedUser: 'User has been created',
		errorCreatedUser: 'An error ocurr while creating the user, please try again.',
		editUser: 'Edit User',
		editedUser: 'User Edited',
		okEditUser: 'User data has been modified',
		newNetworkUser: 'New Evaluator User',
		female: 'Female',
		male:	'Male',
		selectJobType: 'Select Job Type',
		contract: 'With Contract',
		independent: 'No Contract',
		company: 'Company',
		enterCompany: 'Enter Company',
		jobTypeA: 'Coach',
		jobTypeB: 'Physical trainer',
		jobTypeC: 'Prof. Physical Education',
		jobTypeD: 'Kinesiologist',
		jobTypeE: 'Nutritionist',
		jobTypeF: 'Plastic Surgeon',
		jobTypeG: 'Bariatrician',
		jobTypeH: 'Massage therapist',
		jobTypeI: 'Massage therapist',
		jobTypeJ: 'General Physician',
		jobTypeK: 'Nutritionist',
		jobTypeL: 'Cardiologist',
		jobTypeM: 'Diabetologist',
		jobTypeN: 'Psychologist',
		jobTypeO: 'Other medical specialty',
		specialty: 'Specialty',
		selectSpecialty: 'Select Specialty',
		job: 'Job',
		editNetworkUser: 'Edit Network User',
		newEvaluation: 'New Evaluation',
		evaluateData: 'User Data',
		userToEvaluate: 'User to Evaluate',
		selectUserToEvaluate: 'Select User to Evaluate',
		evaluationType: 'Evaluation Type',
		imcMethod: 'Measuring Tape Method',
		twoPMethod: '2 Folds Method',
		fourPMethod: '4 Folds Method',
		bioMethod: 'Bioimpedance meter method',
		enterHeight: 'Enter Height in centimeters',
		enterWeight: 'Enter weight in kilograms',
		enterWaistPerimeter: 'Enter waist perimeter in cm.',
		enterHipPerimeter: 'Enter hip perimeter in cm.',
		enterDiastolic: 'Enter diastolic pressure',
		enterSistolic: 'Enter sistolic pressure',
		pTriceps: 'Triceps fold',
		enterPTriceps: 'Enter triceps fold in mm.',
		pSubescapular: 'Subscapular fold',
		enterPSubescapular: 'Enter subscapular fold in mm.',
		pAbdominal: 'Abdominal fold',
		enterPAbdominal: 'Enter abdominal fold in mm.',
		pCuadriceps: 'Quadriceps fold',
		enterPCuadriceps: 'Enter quadriceps crease in mm.',
		otherDisease: 'Other disease',
		rangeEntrenoA: '1 to 3 weeks',
		rangeEntrenoB: '1 to 3 months',
		rangeEntrenoC: '4 to 6 months',
		rangeEntrenoD: '7 to 11 months',
		rangeEntrenoE: '1 year',
		rangeEntrenoF: 'More than 1 year',
		timesAWeek: 'times a week',
		hasECNTtitle: 'Has any Chronic noncommunicable diseases (CNCD)',
		markIntreatment: 'Check if the user is in treatment',
		hasAutoinmuneTitle: 'Has any Autoimmune disease',
		hasDificulToBreath:'Has difficulty breathing?',
		feverCaution: 'Caution: this value is fever indicator',
		clinicalData: 'Clinical Data',
		rockportFieldsError: 'Please verify the rockport test fields',
		rockportTest: 'Rockport Test',
		rockportMileTime: 'Mile Time',
		BPM: 'Beats Per Minute',
		oxConsumption: 'Oxygen consumption',
		trainingFrequencyQuestion: 'How Often?',
		noSinceTrainingQuestion: 'How long have you NOT been exercising?',
		sinceTrainingQuestion: 'How long have you been exercising?',
		doesExercise: 'Does exercise?',
		back: 'Previous',
		next: 'Next',
		fieldRequiredEv: 'These fields are required',
		myNetwork: 'My Network',
		profile: 'Profile',
		logout: 'Log Out',
		creditsQty: 'Available credits',
		loadingInfo: 'Loading Information',
		dashUsuarios: 'Registered Users',
		evaluatedUsers: 'Evaluated Users',
		dashNetworkUsers: 'My Colaborators',
		smoker: 'Does Smoke?',
		drinkAlcohol: 'Drinks Alcohol?',
		optional: 'Optional',
		trainingTime: 'Training Time',
		effortType: 'Effort Type',
		trainingVolumePercent: 'Traning Volume (%)',
		trainingVolumeMinutes: 'Traning Volume (Minutes)',
		minutes: 'Minutes',
		seconds: 'Seconds',
		training: 'Training',
		metabolicgastoTraining: 'Metabolic Expenditure Training',
		warmUp: 'Warm Up',
		areobic: 'Aerobics',
		increment: 'Increase',
		minorTo: 'Less than',
		equalTo: 'Equal to',
		mayorTo: 'Bigger than',
		rcaloricoa: 'Sedentary',
		rcaloricob: 'Low Physical Activity',
		rcaloricoc: 'Moderate Physical Activity',
		rcaloricod: 'High Physical Activity',
		moveCreditsTitle: 'Modify user credits',
		credits: 'Credits',
		enterCredits: 'Entrer Credits',
		modify: 'Modify',
		changeCreditsDescription: 'If the amount of credits you assign to the user is greater than the one they currently have, credits will be deducted from your account to add them. If, on the other hand, the amount of credits assigned is less than the current one, the difference will be added to your credits.',
		addNetworkUser: 'Add Network User',
		armPerimeter: 'Right Arm Perimeter',
		legPerimeter: 'Right Leg Perimeter',
		enterArmPerimeter: 'Enter Right Arm Perimeter',
		enterLegPerimeter: 'Enter Right Leg Perimeter',
		userDocumentsTitle: 'User Documents Associations',
		newUserDocumentsTitle: 'New User Documents Association',
		addAttachment: 'Add File',
		title: 'Title',
		enterTitle: 'Enter Title',
		description: 'Description',
		enterDescription: 'Enter Description',
		maxFileSizeAdvice: 'Max. File Size: 10 MB',
		created: 'Created',
		viewFile: 'Open file',
		metabolicAge: 'Physiological Age',
		visceralFat: 'Visceral Fat',
		muscularPercent: 'Muscular Mass Percent',
		residualPercent: 'Residual Mass Percent',
		muscularWeight: 'Muscular Weight',
		residualWeight: 'Residual Weight',
		graphMuscular: '% Muscular',
		graphResidual: '% Residual',
		acceptable:'Acceptable',
		aboveNormal:'Above normal',
		risk:'Risk',
		reference: 'Reference',
		muscularMass: 'Muscular Mass',
		fit: 'Fit',
		elite: 'Elite',
		good: 'Good',
		bad: 'Badd',
		veryBad: 'Very Bad',
		highRisk: 'High risk',
		sedentary: 'Sedentary',
		sports: 'Sports',
		athletic: 'Athletic',
		modalChatTitle: 'Chats',
		enterChatText: 'Write your message',
		examsTitle: 'Exams',
		exam: 'Exam',
		value: 'Value',
		percentile: 'Percentile',
		measureUnit: 'Measure Unit',
		limitValue: 'Limit Value',
		chatRoomMessageA: 'Select a user to view the chat',
		chatRoomMessageB: 'If you want to start a conversation with a new user, you can do it from the corresponding list by clicking the green button',
		noChatRoomMessageA: 'You have no conversation created',
		noChatRoomMessageB: 'To create a new conversation go to the "Client" or "Collaborator" section and press the green button',
		okCreditsTitle: 'Loaded credits',
		okCredits: 'The credits have been loaded correctly',
		changePassword: 'Change Password',
		password: 'Password',
		confirmPassword: 'Confirm Password',
		passwordDoNotMatch: 'Passwords entered do not match',
		okChangePassTitle: 'Changed password',
		okChangePass: 'The password has been changed successfully',
		enterEmailForPassReset: 'Enter a valid email for which you want to recover the password',
		okResetPassTitle: 'Password reset',
		okResetPass: 'We have sent you an email with the details',
		nutritionalRequirements: 'Nutritional Requirement',
		proteins: 'Protein',
		carbohydrates: 'Carbohydrates',
		lipidsAndOthers: 'Lipids and Others',
		macronutrients: 'Macronutrients',
		nutritionalTraffic: 'Nutritional traffic',
		proteinsExplainText: 'They are found in red, white, lean meats, skim dairy, help build muscle tissue and provide 4 calories per gram.',
		carbohydratesExplainText: 'They are found in wheat derivatives (bread, flour, pasta, etc.), grains, cereals, fruits and vegetables. They provide 4 calories per gram.',
		lipidsAndOthersExplainText: 'They are found in nuts, dates, avocados, salmon, olive oil, etc. They provide 9 calories per gram.',
		semaforoTitleA: 'Avoid eating the following foods',
		semaforoTextA: 'Foods with added sugars and / or excess saturated fats such as: Sugar, excess honey, pastry products, chocolate, candied fruits, delicacy, quince paste, jam, whole milk desserts with added sugar, canned food syrup; sugary nectar; sugary drinks, waters flavored with sugar, fried foods, butter, butter, margarine, mayonnaise, sugary yogurt, creams, sausages (pate, viennese, sausages, cured meats), organ meats, junk food (hamburgers; complete; steaks; pizzas, etc.) fruits dried candied or with the addition of salt; French fries, twigs, or any type of snack of that type, yellow cheese (gouda, buttery, etc).',
		semaforoTitleB: 'Prefer the consumption of the following foods',
		semaforoTextB: 'Water, Water flavored with citrus or herbs, fruits, vegetables, legumes, whole grains (whole wheat bread, oats, brown rice, whole grains for breakfast, etc.), nuts (portioned without added sugar or salt), dairy-free sugar (yogurt and milk), Quesillo, chacra cheese, fresh cheese, nuts (portioned without added sugar or salt), sugar-free cereal bars, Herbal infusions, eggs, fish, lean red meat, chicken, turkey , chia, flaxseed, avocado, jellies without sugar.',
		createdBy: 'Uploaded by',
		evaluationCreatedByYou: 'Evaluation created by you',
		myChildUserMessage: 'You are the parent account of this user',
		acceptedTerms: 'I accept terms and conditions',
		ageUnder13: 'The selected user is under 13 years old, please correct it before continuing with the evaluation.',
		ageUnder13Button: 'Modify User',
		deleteAskToConfirm: 'You are about to delete an item. Are you sure?.',
		confirmBirthDataIsOkRegister: 'Your age is 13. Is this information ok?. Confirm to submit the form.',
		selection: 'Select',
		mantainVolume: 'Maintain volume',
		reduceVolume: 'Reduce Volume',
		addVolume: 'Increase Volume',
		'Hipertensión': 'Hypertension',
		'Hipercolesteremia': 'Hypercholesterolemia',
		'Diabetes': 'Diabetes',
		'Obesidad': 'Obesity',
		'Hígado Graso': 'Fatty Liver',
		'Lupus': 'Lupus',
		'Celíaca': 'Celiac',
		'Cáncer': 'Cancer',
		'VIH': 'HIV',
		'Artritis': 'Rheumatoid arthritis',
	},
	'es': {
		spanish: 'Español',
		english: 'Inglés',
		portugues: 'Portugués',
		evaluationTitle: 'Evaluación',
		loadingEvaluation: 'Cargando Evaluación',
		evaluatedUserData: 'Datos del Evaluado',
		name: 'Nombre',
		gender: 'Sexo',
		email: 'Email',
		phone: 'Teléfono',
		region: 'Región',
		comuna: 'Comuna',
		address: 'Dirección',
		birth: 'Fecha Nacimiento',
		age: 'Edad',
		temperature: 'Temperatura',
		dificulToBreath: 'Dificultad para respirar',
		cronAge: 'Edad Crónologica',
		height: 'Estatura',
		weight: 'Peso',
		alcohol: 'Alcohol',
		smoke: 'Fuma',
		years: 'Años',
		months: 'Meses',
		yes: 'Si',
		no: 'No',
		evaluatedUserClinicalData: 'Datos Clínicos del Evaluado',
		entDisease: 'Enfermedades E.N.T',
		noEntDisease: 'El usuario no declara enfermedades ECNT',
		inTreatment: 'En tratamiento',
		noTreatment: 'Sin tratamiento',
		autoinmuneDisease: 'Enfermedades Autoinmunes',
		noAutoinmuneDisease: 'El usuario no declara enfermedades Autoinmunes',
		entryData: 'Datos de Entrada',
		basicData: 'Datos Básicos',
		neckPerimeter: 'Perímetro Cuello',
		enterNeckPerimeter: 'Ingresar Perímetro Cuello',
		waistPerimeter: 'Perímetro Cintura',
		hipPerimeter: 'Perímetro Cadera',
		rightArmPerimeter: 'Perímetro Brazo Derecho',
		rightLegPerimeter: 'Perímetro Pierna Derecha',
		diastolic: 'Presión Diastólica',
		sistolic: 'Presión Sistólica',
		imc: 'IMC',
		fatPercent: 'Porcentaje de Grasa',
		enterFatPercent: 'Ingrese Porcentaje de Grasa',
		magraPercent: 'Porcentaje Masa Magra',
		imb: 'Indice metabólico Basal',
		fisiologicalData: 'Datos Fisiológicos',
		calories: 'calorías',
		bodyComposition: 'Composición Corporal',
		fatWeight: 'Peso Graso',
		magroWeight: 'Peso Magro',
		fullWeight: 'Peso Total',
		graphFat: '% Grasa',
		graphLean: '% Masa Magra',
		bodyCompositionAnalysis: 'Análisis Composición Corporal',
		imcAnalysis: 'Análisis IMC',
		imcIdeal: 'IMC Ideal',
		refImcRange: 'Rangos IMC de Referencia',
		veryLow: 'Muy Bajo',
		low: 'Bajo',
		normal: 'Normal',
		high: 'Alto',
		veryHigh: 'Muy Alto',
		moderate: 'Moderado',
		optimum: 'Óptimo',
		highModerate: 'Moderado Alto',
		extremelyHigh: 'Extremadamente Alto',
		to: 'Hasta',
		from: 'Desde',
		waistHeightAnalysis: 'Análisis Cintura/Estatura',
		waistHipAnalysis: 'Análisis Cintura/Cadera',
		waistHeightIndex: 'Índice Cintura/Estatura',
		waistHipIndex: 'Índice Cintura/Cadera',
		referenceRange: 'Rangos de Referencia',
		analysis: 'Análisis',
		severeThinness: 'Delgadez Severa',
		slightThinness: 'Delgadez Leve',
		normalWeight: 'Peso Normal ',
		overweight: 'Sobrepeso',
		highOverweight: 'Sobrepeso Elevado',
		morbidObesity: 'Obesidad Mórbida',
		scoring: 'Scoring',
		caloricRequirement: 'Requerimiento Calórico',
		minimum: 'Mínimo',
		average: 'Promedio',
		maximum: 'Máximo',
		entryLevel: 'Nivel de Entrada',
		trainingLevel: 'Nivel de Entrenamiento',
		risk: 'Riesgo',
		normal: 'Normal',
		sedentary: 'Sedentario',
		veryGood: 'Muy Bueno',
		basic: 'Básico',
		medium: 'Intermedio',
		advance: 'Avanzado',
		historic: 'Historial',
		date: 'Fecha',
		sendEvaluation: 'Enviar Evaluación',
		sendEvaluationByEmail: 'Enviar Evaluación por Email',
		enterEmail: 'Ingresa el email',
		myEvaluations: 'Mis Evaluaciones',
		addEvaluation: 'Agregar Evaluación',
		lastName: 'Apellido',
		creationDate: 'Fecha Creación',
		actions: 'Acciones',
		update: 'Actualizar',
		deletedEvaluation: 'Evaluación Eliminada',
		deletedEvaluationMessage: 'Se ha eliminado la evaluación.',
		error: 'Error',
		errorMessage: 'Hubo un error en la operación, por favor intenta nuevamente.',
		myClients: 'Mis Clientes',
		addClient: 'Agregar Cliente',
		newUser: 'Nuevo Usuario',
		enterName: 'Ingresa Nombre',
		enterLastname: 'Ingresa Apellido',
		selectGender: 'Selecciona Sexo',
		birthDescription: 'Presiona el icono de calendario para seleccionar fecha. Edad entre 13 y 100 años.',
		enterPhone: 'Ingresar Teléfono',
		enterAddress: 'Ingresar Dirección',
		country: 'País',
		selectCountry: 'Selecciona País',
		region: 'Región',
		selectRegion: 'Selecciona Región',
		comuna: 'Ciudad',
		selectComuna: 'Selecciona Ciudad',
		selectCompany: 'Selecciona Empresa/Institución (Opcional)',
		allFieldsRequired: 'Todos los campos son obligatorios',
		createUser: 'Crear Usuario',
		createdUser: 'Usuario Creado',
		okCreatedUser: 'Se ha creado correctamente el usuario',
		errorCreatedUser: 'Hubo un error en la creación del usuario, por favor intenta nuevamente.',
		editUser: 'Editar Usuario',
		editedUser: 'Usuario Modificado',
		okEditUser: 'Se han modificado los datos del usuario',
		newNetworkUser: 'Nuevo Usuario Evaluador',
		female: 'Femenino',
		male:	'Masculino',
		selectJobType: 'Seleccione tipo trabajo',
		contract: 'Dependiente',
		independent: 'Independiente',
		company: 'Empresa',
		enterCompany: 'Ingresa Empresa',
		jobTypeA: 'Entrenador',
		jobTypeB: 'Preparador Físico',
		jobTypeC: 'Prof. Educ. Física',
		jobTypeD: 'Kinesiólogo',
		jobTypeE: 'Nutricionista',
		jobTypeF: 'Cirujano Plástico',
		jobTypeG: 'Bariatra',
		jobTypeH: 'Masoterapeuta',
		jobTypeI: 'Masajista',
		jobTypeJ: 'Médico General',
		jobTypeK: 'Nutriólogo',
		jobTypeL: 'Cardiólogo',
		jobTypeM: 'Diabetólogo',
		jobTypeN: 'Psicólogo',
		jobTypeO: 'Otra especialidad médica',
		specialty: 'Especialidad',
		selectSpecialty: 'Seleciona Especialidad',
		job: 'Trabajo',
		editNetworkUser: 'Editar Usuario Evaluador',
		newEvaluation: 'Nueva Evaluación',
		evaluateData: 'Datos Evaluado',
		userToEvaluate: 'Usuario a evaluar',
		selectUserToEvaluate: 'Seleccione Evaluado',
		evaluationType: 'Forma de Evaluación',
		imcMethod:'Método Cinta Métrica',
		bioMethod: 'Método Bioimpedianciometro',
		twoPMethod:'Método 2 Pliegues',
		fourPMethod:'Método 4 Pliegues',
		enterHeight: 'Ingrese estatura en centímetros',
		enterWeight: 'Ingrese peso en kilogramos',
		enterWaistPerimeter: 'Ingrese perimetro cintura en cm.',
		enterHipPerimeter: 'Ingrese perimetro cadera en cm.',
		enterDiastolic: 'Ingrese presión diastólica',
		enterSistolic: 'Ingrese presión sistólica',
		pTriceps: 'Pliegue Triceps',
		enterPTriceps: 'Ingrese pliegue triceps en mm.',
		pSubescapular: 'Pliegue Subescapular',
		enterPSubescapular: 'Ingrese pliegue subescapular en mm.',
		pAbdominal: 'Pliegue Abdominal',
		enterPAbdominal: 'Ingrese pliegue abdominal en mm.',
		pCuadriceps: 'Pliegue Cuadriceps',
		enterPCuadriceps: 'Ingrese pliegue cuadriceps en mm.',
		otherDisease: 'Otra enfermedad',
		rangeEntrenoA: '1 to 3 semanas',
		rangeEntrenoB: '1 a 3 meses',
		rangeEntrenoC: '4 a 6 meses',
		rangeEntrenoD: '7 a 11 meses',
		rangeEntrenoE: '1 año',
		rangeEntrenoF: 'Más de 1 año',
		timesAWeek: 'veces a la semana',
		hasECNTtitle: 'Presenta alguna de estas Enfermedades Crónicas No Transmisibles (ECNT)',
		markIntreatment: 'Marque si está en tratamiento',
		hasAutoinmuneTitle: 'Presenta alguna enfermedad Autoinmune',
		hasDificulToBreath:'¿Presenta dificultad para respirar?',
		feverCaution: 'Precaución: El valor ingresado indica fiebre',
		clinicalData: 'Datos Clínicos',
		rockportFieldsError: 'Por favor verifica los campos del test rockport',
		rockportTest: 'Test de Rockport',
		rockportMileTime: 'Tiempo por milla',
		BPM: 'Pulsaciones por minuto',
		oxConsumption: 'Consumo Oxígeno',
		trainingFrequencyQuestion: '¿Con que frecuencia?',
		noSinceTrainingQuestion: '¿Hace cuanto tiempo NO hace ejercicio físico?',
		sinceTrainingQuestion: '¿Hace cuanto tiempo hace ejercicio físico?',
		doesExercise: '¿Se ejercita?',
		fieldRequiredEv: 'Los campos marcados son obligatorios',
		myNetwork: 'Mis Colaboradores',
		profile: 'Perfil',
		logout: 'Salir',
		creditsQty: 'Cantidad de créditos disponibles',
		loadingInfo: 'Cargando Información',
		dashUsuarios: 'Usuarios Registrados',
		evaluatedUsers: 'Usuarios Evaluados',
		dashNetworkUsers: 'Mis Colaboradores',
		smoker: '¿Fumador?',
		drinkAlcohol: '¿Bebe Alcohol?',
		optional: 'Opcional',
		trainingTime: 'Tiempo Entrenamiento',
		effortType: 'Tipo de Esfuerzo',
		trainingVolumePercent: 'Volumen Entrenamiento (%)',
		trainingVolumeMinutes: 'Volumen Entrenamiento (Minutos)',
		minutes: 'Minutos',
		seconds: 'Segundos',
		training: 'Entrenamiento',
		metabolicgastoTraining: 'Gasto Metabólico Entrenamiento',
		warmUp: 'Calentamiento',
		areobic: 'Aeróbica',
		increment: 'Incremento',
		minorTo: 'Menor a',
		equalTo: 'Igual a',
		mayorTo: 'Mayor a',
		rcaloricoa: 'Sedentario',
		rcaloricob: 'Actividad Física Baja ',
		rcaloricoc: 'Actividad Física Moderada',
		rcaloricod: 'Actividad Física Alta',
		moveCreditsTitle: 'Modificar créditos de este usuario',
		credits: 'Créditos',
		enterCredits: 'Ingrese créditos',
		modify: 'Modificar',
		back: 'Anterior',
		next: 'Siguiente',
		changeCreditsDescription: 'Si la cantidad de créditos que asignas al usuario es mayor a la que tiene actualmente se descontarán créditos de tu cuenta para agregarselos. Si por el contrario, la cantidad de créditos asignada es menor a la actual se sumará la diferencia a tus créditos.',
		addNetworkUser: 'Agregar Usuario Evaluador',
		armPerimeter: 'Perímetro Brazo Derecho',
		legPerimeter: 'Perímetro Pierna Derecha',
		enterArmPerimeter: 'Ingrese Perímetro Brazo Derecho',
		enterLegPerimeter: 'Ingrese Perímetro Pierna Derecha',
		userDocumentsTitle: 'Asociación de Documentos del Usuario',
		newUserDocumentsTitle: 'Asociar nuevo documento al usuario',
		addAttachment: 'Agregar Archivo',
		title: 'Título',
		enterTitle: 'Ingresa Título',
		description: 'Descripción',
		enterDescription: 'Ingresa Descripción',
		maxFileSizeAdvice: 'Tamaño máximo de archivo: 10 MB',
		created: 'Creado',
		viewFile: 'Visualizar archivo',
		metabolicAge: 'Edad Fisiológica',
		visceralFat: 'Grasa Visceral',
		muscularPercent: 'Porcentaje Masa Muscular',
		residualPercent: 'Porcentaje Masa Residual',
		muscularWeight: 'Peso Muscular',
		residualWeight: 'Peso Residual',
		graphMuscular: '% Muscular',
		graphResidual: '% Residual',
		acceptable:'Aceptable',
		aboveNormal:'Sobre lo normal',
		risk:'Riesgo',
		reference: 'Referencia',
		muscularMass: 'Masa Muscular',
		fit: 'En Forma',
		elite: 'Élite',
		good: 'Bueno',
		bad: 'Malo',
		veryBad: 'Muy Malo',
		highRisk: 'Alto Riesgo',
		sedentary: 'Sedentario',
		sports: 'Deportivo',
		athletic: 'Atlético',
		modalChatTitle: 'Chats',
		enterChatText: 'Escribe un mensaje',
		examsTitle: 'Exámenes',
		exam: 'Examen',
		value: 'Valor',
		percentile: 'Percentil',
		measureUnit: 'U. de Medida',
		limitValue: 'Valor Límite',
		chatRoomMessageA: 'Selecciona un usuario para visualizar el chat',
		chatRoomMessageB: 'Si deseas iniciar una conversación con un nuevo usuario puedes hacerlo desde el listado correspondiente clickeando el botón verde',
		noChatRoomMessageA: 'No tienes ninguna conversación creada',
		noChatRoomMessageB: 'Para crear una conversación nueva dirígete a la sección de usuario "Cliente" o "Colaborador" y presiona el botón verde',
		okCreditsTitle: 'Créditos cargados',
		okCredits: 'Se han cargado correctamente los créditos',
		changePassword: 'Cambiar Contraseña',
		password: 'Contraseña',
		confirmPassword: 'Confirmar contraseña',
		passwordDoNotMatch: 'Las contraseñas ingresadas no coinciden',
		okChangePassTitle: 'Contraseña modificada',
		okChangePass: 'La contraseña ha sido modificada con éxito',
		enterEmailForPassReset: 'Ingresa un email válido para el cual deseas recuperar la contraseña',
		okResetPassTitle: 'Contraseña restablecida',
		okResetPass: 'Te hemos enviado un correo con los detalles',
		nutritionalRequirements: 'Requerimiento Nutricional',
		proteins: 'Proteínas',
		carbohydrates: 'Carbohidratos',
		lipidsAndOthers: 'Lípidos y Otros',
		macronutrients: 'Macronutrientes',
		nutritionalTraffic: 'Semáforo nutricional',
		proteinsExplainText: 'Se encuentran en carnes rojas, blancas, magras, lácteos descremados, ayudan a formar tejido muscular y aportan 4 calorías por gramo.',
		carbohydratesExplainText: 'Se encuentran en los derivados de trigo (pan, harinas, pastas, etc.), granos, cereales, frutas y verduras. Aportan 4 calorías por gramo.',
		lipidsAndOthersExplainText: 'Se encuentran en frutos secos, dáctiles, palta, salmón, aceite de oliva, etc. Aportan 9 calorías por gramo.',
		semaforoTitleA: 'Evitar el consumo de los siguientes alimentos',
		semaforoTextA: 'Alimentos con adición de azúcares y/o exceso de grasas saturadas como: Azúcar, miel en cantidad excesiva, productos de repostería, chocolate, frutas confitadas, manjar, dulce de membrillo, mermelada, postres de leche enteros y con adición de azúcar, conservas en almíbar; néctar azucarados; bebidas azucaradas, aguas saborizadas con azúcar, Frituras, manteca, mantequilla, margarina, mayonesa, yogurt azucarados, cremas, embutidos (Paté, vienesas, chorizos, cecinas), vísceras, comida chatarra (hamburguesas; completos; churrascos; pizzas, etc) frutos secos confitados o con adición de sal; papas fritas, ramitas, o cualquier tipo de snack de ese tipo, queso amarillo (gouda, mantecoso,etc).',
		semaforoTitleB: 'Preferir el consumo de los siguientes alimentos',
		semaforoTextB: 'Agua, Agua saborizada con cítricos o hierbas, frutas, verduras, legumbres, cereales integrales(pan integral, avena, arroz integral, cereales integrales para el desayuno, etc), frutos secos (porcionados sin adición de azúcar o sal), lácteos libres de azúcar(yogurt y leche), Quesillo, queso chacra, queso fresco, frutos secos (porcionados sin adición de azúcar o sal), barras de cereal sin azúcar, Infusiones de hierbas, huevo, pescados, carnes rojas de cortes magros, pollo, pavo, chía, linaza, palta, jaleas sin azúcar.',
		createdBy: 'Subido por',
		evaluationCreatedByYou: 'Evaluación creada por ti',
		myChildUserMessage: 'Eres la cuenta padre de este usuario',
		acceptedTerms: 'Acepto términos y condiciones',
		ageUnder13: 'El usuario seleccionado tiene una edad menor a 13 años, por favor corríjalo antes de realizar la evaluación.',
		ageUnder13Button: 'Modificar Usuario',
		deleteAskToConfirm: 'Vas a eliminar un elemento. ¿Estás seguro?.',
		confirmBirthDataIsOkRegister: 'Tu edad es 13 años. ¿Está información es correcta?. Confirma para enviar el formulario.',
		selection: 'Seleccione',
		mantainVolume: 'Mantener Volumen',
		reduceVolume: 'Reducir Volumen',
		addVolume: 'Aumentar Volumen',
		'Hipertensión': 'Hipertensión',
		'Hipercolesteremia': 'Hipercolesteremia',
		'Diabetes': 'Diabetes',
		'Obesidad': 'Obesidad',
		'Hígado Graso': 'Hígado Graso',
		'Lupus': 'Lupus',
		'Celíaca': 'Celíaca',
		'Cáncer': 'Cáncer',
		'VIH': 'VIH',
		'Artritis': 'Artritis',
	},
	'pt': {
		spanish: 'Espanhol',
		english: 'Inglês',
		portugues: 'Português',
		evaluationTitle: 'Avaliação',
		loadingEvaluation: 'Avaliação de carregamento',
		evaluatedUserData: 'Dados dos Avaliados',
		name: 'Nome',
		gender: 'Sexo',
		email: 'O email',
		phone: 'Telefone',
		region: 'Região',
		comuna: 'Comuna',
		address: 'Endereço',
		birth: 'Data de nascimento',
		age: 'Era',
		temperature: 'Temperatura',
		dificulToBreath: 'Dificuldade para respirar',
		cronAge: 'Idade cronologica',
		height: 'Altura',
		weight: 'Peso',
		alcohol: 'Álcool',
		smoke: 'Fuma',
		years: 'Anos',
		months: 'Meses',
		yes: 'sim',
		no: 'Não',
		evaluatedUserClinicalData: 'Dados clínicos dos avaliados',
		entDisease: 'Doenças E.N.T',
		noEntDisease: 'O usuário não declara doenças DCNT',
		inTreatment: 'Em tratamento',
		noTreatment: 'Sem tratamento',
		autoinmuneDisease: 'Doenças autoimunes',
		noAutoinmuneDisease: 'O usuário não declara doenças autoimunes',
		entryData: 'Dados de entrada',
		basicData: 'Dados básicos',
		neckPerimeter: 'Perímetro do pescoço',
		enterNeckPerimeter: 'Entre no perímetro do pescoço',
		waistPerimeter: 'Perímetro da cintura',
		hipPerimeter: 'Circunferência do quadril',
		rightArmPerimeter: 'Perímetro do braço direito',
		rightLegPerimeter: 'Perímetro da perna direita',
		diastolic: 'Pressão diastólica',
		sistolic: 'Pressão sistólica',
		imc: 'IMC',
		fatPercent: 'Média de gordura',
		enterFatPercent: 'Digite a porcentagem de gordura',
		magraPercent: 'Porcentagem de massa magra',
		imb: 'Taxa metabólica Basal',
		fisiologicalData: 'Dados Fisiológicos',
		calories: 'calorias',
		bodyComposition: 'Composição do corpo',
		fatWeight: 'Peso gordo',
		magroWeight: 'Peso Magro',
		fullWeight: 'Peso total',
		graphFat: '% Gordura',
		graphLean: '% Massa Enxuta',
		bodyCompositionAnalysis: 'Análise de composição corporal',
		imcAnalysis: 'Análise de IMC',
		imcIdeal: 'IMC ideal',
		refImcRange: 'Faixas de IMC de referência',
		veryLow: 'Muito baixo',
		low: 'Baixo',
		normal: 'Normal',
		high: 'Alto',
		veryHigh: 'Muito alto',
		moderate: 'Moderado',
		optimum: 'Ótimo',
		highModerate: 'Moderado alto',
		extremelyHigh: 'Extremamente alto',
		to: 'Até',
		from: 'A partir de',
		waistHeightAnalysis: 'Análise de cintura / altura',
		waistHipAnalysis: 'Análise de cintura / quadril',
		waistHeightIndex: 'Índice de cintura / altura',
		waistHipIndex: 'Relação cintura quadril',
		referenceRange: 'Faixas de Referência',
		analysis: 'Análise',
		severeThinness: 'Magreza severa',
		slightThinness: 'Ligeira magreza',
		normalWeight: 'Peso normal',
		overweight: 'Excesso de peso',
		highOverweight: 'Sobrepeso alto',
		morbidObesity: 'Obesidade mórbida',
		scoring: 'Pontuação',
		caloricRequirement: 'Necessidade Calórica',
		minimum: 'Mínimo',
		average: 'Média',
		maximum: 'Máximo',
		entryLevel: 'Nível de entrada',
		trainingLevel: 'Nível de treinamento',
		risk: 'Risco',
		normal: 'Normal',
		sedentary: 'Sedentário',
		veryGood: 'Muito bom',
		basic: 'Básico',
		medium: 'Intermediário',
		advance: 'Avançado',
		historic: 'Registro',
		date: 'Encontro',
		sendEvaluation: 'Enviar Avaliação',
		sendEvaluationByEmail: 'Enviar avaliação por e-mail',
		enterEmail: 'Digite o e-mail',
		myEvaluations: 'Minhas Avaliações',
		addEvaluation: 'Adicionar Avaliação',
		lastName: 'Sobrenome',
		creationDate: 'Data de criação',
		actions: 'Ações',
		update: 'Atualizar',
		deletedEvaluation: 'Avaliação Removida',
		deletedEvaluationMessage: 'A avaliação foi removida.',
		error: 'Erro',
		errorMessage: 'Ocorreu um erro na operação, tente novamente.',
		myClients: 'Meus clientes',
		addClient: 'Adicionar Cliente',
		newUser: 'Novo usuário',
		enterName: 'Insira o nome',
		enterLastname: 'Digite o sobrenome',
		selectGender: 'Selecione o sexo',
		birthDescription: 'Pressione o ícone do calendário para selecionar a data. Idade entre 13 e 100 anos.',
		enterPhone: 'Digite o telefone',
		enterAddress: 'Insira o endereço',
		country: 'País',
		selectCountry: 'Selecione o pais',
		region: 'Região',
		selectRegion: 'Selecione a região',
		comuna: 'Cidade',
		selectComuna: 'Selecione a cidade',
		selectCompany: 'Selecione Empresa/Instituição (Opcional)',
		allFieldsRequired: 'Todos os campos são obrigatórios',
		createUser: 'Criar usuário',
		createdUser: 'Criado pelo usuário',
		okCreatedUser: 'O usuário foi criado com sucesso',
		errorCreatedUser: 'Ocorreu um erro ao criar o usuário, tente novamente.',
		editUser: 'Editar usuário',
		editedUser: 'Usuário Modificado',
		okEditUser: 'Os dados do usuário foram modificados',
		newNetworkUser: 'Novo usuário avaliador',
		female: 'Feminino',
		male: 'Masculino',
		selectJobType: 'Selecione o tipo de trabalho',
		contract: 'Dependente',
		independent: 'Independente',
		company: 'Empresa',
		enterCompany: 'Entrar na empresa',
		jobTypeA: 'Treinador',
		jobTypeB: 'Preparador físico',
		jobTypeC: 'Prof. Educ. Física',
		jobTypeD: 'Cinesiologista',
		jobTypeE: 'Nutricionista',
		jobTypeF: 'Cirurgião plástico',
		jobTypeG: 'Bariátrico',
		jobTypeH: 'Massoterapeuta',
		jobTypeI: 'Massoterapeuta',
		jobTypeJ: 'Médico geral',
		jobTypeK: 'Nutricionista',
		jobTypeL: 'Cardiologista',
		jobTypeM: 'Diabetologista',
		jobTypeN: 'Psicólogo',
		jobTypeO: 'Outra especialidade médica',
		specialty: 'Especialidade',
		selectSpecialty: 'Selecione a especialidade',
		job: 'Trabalho',
		editNetworkUser: 'Editar avaliador de usuário',
		newEvaluation: 'Nova Avaliação',
		evaluateData: 'Dados avaliados',
		userToEvaluate: 'Usuário para avaliar',
		selectUserToEvaluate: 'Selecione Avaliada',
		evaluationType: 'Formulário de avaliação',
		imcMethod: 'Método de Fita Métrica',
		bioMethod: 'Método do medidor de bioimpedância',
		twoPMethod: 'Método 2 dobras',
		fourPMethod: 'Método 4 dobras',
		enterHeight: 'Insira a altura em centímetros',
		enterWeight: 'Insira o peso em quilogramas',
		enterWaistPerimeter: 'Insira a circunferência da cintura em cm.',
		enterHipPerimeter: 'Insira a circunferência do quadril em cm.',
		enterDiastolic: 'Insira a pressão diastólica',
		enterSistolic: 'Insira a pressão sistólica',
		pTriceps: 'Dobra de tríceps',
		enterPTriceps: 'Insira a dobra do tríceps em mm.',
		pSubescapular: 'Dobra subescapular',
		enterPSubescapular: 'Insira a dobra subescapular em mm.',
		pAbdominal: 'Prega abdominal',
		enterPAbdominal: 'Entre na prega abdominal em mm.',
		pCuadriceps: 'Dobra de quadríceps',
		enterPCuadriceps: 'Insira a prega do quadríceps em mm.',
		otherDisease: 'Outra doença',
		rangeEntrenoA: '1 a 3 semanas',
		rangeEntrenoB: '1 a 3 meses',
		rangeEntrenoC: '4 a 6 meses',
		rangeEntrenoD: '7 a 11 meses',
		rangeEntrenoE: '1 ano',
		rangeEntrenoF: 'Mais de 1 ano',
		timesAWeek: 'vezes por semana',
		hasECNTtitle: 'Apresenta qualquer uma dessas doenças crônicas não transmissíveis (DCNT)',
		markIntreatment: 'Verifique se você está em tratamento',
		hasAutoinmuneTitle: 'Tem alguma doença autoimune',
		hasDificulToBreath: 'Você tem dificuldade para respirar?',
		feverCaution: 'O valor inserido indica febre',
		clinicalData: 'Dados clínicos',
		rockportFieldsError: 'Por favor, verifique os campos de teste do rockport',
		rockportTest: 'Teste Rockport',
		rockportMileTime: 'Tempo em milhas',
		BPM: 'Batimentos por minuto',
		oxConsumption: 'Consumo de oxigenio',
		trainingFrequencyQuestion: 'Com que frequência?',
		noSinceTrainingQuestion: 'Há quanto tempo você NÃO faz exercícios?',
		sinceTrainingQuestion: 'Há quanto tempo você faz exercícios?',
		doesExercise: 'Ele malha?',
		fieldRequiredEv: 'Os campos marcados são obrigatórios',
		myNetwork: 'Minha rede',
		profile: 'Perfil',
		logout: 'Saia',
		creditsQty: 'Quantidade de créditos disponíveis',
		loadingInfo: 'Carregando informações',
		dashUsuarios: 'Usuários registrados',
		evaluatedUsers: 'Usuários avaliados',
		dashNetworkUsers: 'Meus colaboradores',
		smoker: '¿Fuma?',
		drinkAlcohol: '¿Bebe álcool?',
		optional: 'Opcional',
		trainingTime: 'Tempo de treino',
		effortType: 'Tipo de Esforço',
		trainingVolumePercent: 'Volume de treinamento (%)',
		trainingVolumeMinutes: 'Volume de treinamento (minutos)',
		minutes: 'Minutos',
		seconds: 'Segundos',
		training: 'Treinamento',
		metabolicgastoTraining: 'Treinamento de despesas metabólicas',
		warmUp: 'Aquecimento',
		areobic: 'Aeróbica',
		increment: 'Aumentar',
		minorTo: 'Menor que',
		equalTo: 'Igual a',
		mayorTo: 'Maior que',
		rcaloricoa: 'Sedentário',
		rcaloricob: 'Baixa Atividade Física',
		rcaloricoc: 'Atividade física moderada',
		rcaloricod: 'Alta Atividade Física',
		moveCreditsTitle: 'Modificar os créditos deste usuário',
		credits: 'Créditos',
		enterCredits: 'Digite os créditos',
		modify: 'Modificar',
		back: 'Anterior',
		next: 'Próximo',
		changeCreditsDescription: 'Se a quantidade de créditos que você atribui ao usuário for maior do que a que ele possui atualmente, os créditos serão deduzidos de sua conta para adicioná-los. Se, por outro lado, o montante de créditos atribuídos for inferior ao atual, a diferença será adicionada aos seus créditos.',
		addNetworkUser: 'Adicionar usuário de rede',
		armPerimeter: 'Perímetro do braço direito',
		legPerimeter: 'Perímetro da perna direita',
		enterArmPerimeter: 'Entrar no perímetro do braço direito',
		enterLegPerimeter: 'Entrar no perímetro da perna direita',
		userDocumentsTitle: 'Associação de documentos do usuário',
		newUserDocumentsTitle: 'Associação de Novos Documentos do Usuário',
		addAttachment: 'Adicionar ficheiro',
		title: 'Título',
		enterTitle: 'Digite o título',
		description: 'Descrição',
		enterDescription: 'Insira a descrição',
		maxFileSizeAdvice: 'Tamanho máximo do arquivo: 10 MB',
		created: 'Criação',
		viewFile: 'Visualizar arquivo',
		metabolicAge: 'Idade Fisiológica',
		visceralFat: 'Gordura Visceral',
		muscularPercent: 'Porcentagem de massa muscular',
		residualPercent: 'Porcentagem de massa residual',
		muscularWeight: 'Peso muscular',
		residualWeight: 'Peso Residual',
		graphMuscular: '% Muscular',
		graphResidual: '% Residual',
		acceptable:'Aceitável',
		aboveNormal:'Quase normal',
		risk:'Risco',
		reference: 'Referência',
		muscularMass: 'Massa Muscular',
		fit: 'Em Forma',
		elite: 'Elite',
		good: 'Bom',
		bad: 'Ruim',
		veryBad: 'Muito ruim',
		highRisk: 'Alto risco',
		sedentary: 'Sedentário',
		sports: 'Esportes',
		athletic: 'Atlético',
		modalChatTitle: 'Chats',
		enterChatText: 'Escreve uma mensagem',
		examsTitle: 'Exames',
		exam: 'Exame',
		value: 'Valor',
		percentile: 'Percentil',
		measureUnit: 'Unidade de medida',
		limitValue: 'Valor limite',
		chatRoomMessageA: 'Selecione um usuário para ver o chat',
		chatRoomMessageB: 'Se você deseja iniciar uma conversa com um novo usuário, você pode fazê-lo na lista correspondente clicando no botão verde',
		noChatRoomMessageA: 'Você não tem nenhuma conversa criada',
		noChatRoomMessageB: 'Para criar uma nova conversa e ao usuário seção Cliente ou Colaborador e pressione o botão verde',
		okCreditsTitle: 'Créditos carregados',
		okCredits: 'Os créditos foram carregados corretamente',
		changePassword: 'Mudar senha',
		password: 'Senha',
		confirmPassword: 'confirmar senha',
		passwordDoNotMatch: 'As senhas inseridas não coincidem',
		okChangePassTitle: 'Senha alterada',
		okChangePass: 'A senha foi alterada com sucesso',
		enterEmailForPassReset: 'Digite um e-mail válido para o qual deseja recuperar a senha',
		okResetPassTitle: 'Redefinição de senha',
		okResetPass: 'Enviamos a você um e-mail com os detalhes',
		nutritionalRequirements: 'Necessidade Nutricional',
		proteins: 'Proteína',
		carbohydrates: 'Carboidratos',
		lipidsAndOthers: 'Lipídios e outros',
		macronutrients: 'Macronutrientes',
		nutritionalTraffic: 'Tráfego nutricional',
		proteinsExplainText: 'Eles são encontrados em carnes vermelhas, brancas, magras, laticínios desnatados, ajudam a construir o tecido muscular e fornecem 4 calorias por grama.',
		carbohydratesExplainText: 'Eles são encontrados em derivados do trigo (pão, farinha, macarrão, etc.), grãos, cereais, frutas e vegetais. Eles fornecem 4 calorias por grama.',
		lipidsAndOthersExplainText: 'Eles são encontrados em nozes, tâmaras, abacates, salmão, azeite de oliva, etc. Eles fornecem 9 calorias por grama.',
		semaforoTitleA: 'Evite comer os seguintes alimentos',
		semaforoTextA: 'Alimentos com adição de açúcares e / ou excesso de gorduras saturadas tais como: Açúcar, excesso de mel, pastelaria, chocolate, frutas cristalizadas, guloseimas, pasta de marmelo, compota, sobremesas de leite integral com adição de açúcar, xarope de conserva; néctar açucarado; bebidas açucaradas, águas aromatizadas com açúcar, alimentos fritos, manteiga, manteiga, margarina, maionese, iogurte açucarado, cremes, salsichas (patê, vienense, salsichas, carnes curadas), carnes orgânicas, junk food (hambúrgueres; completo; bifes; pizzas, etc.) frutas desidratadas cristalizadas ou com adição de sal; Batatas fritas, galhos ou qualquer tipo de petisco desse tipo, queijo amarelo (gouda, amanteigado, etc).',
		semaforoTitleB: 'Prefira o consumo dos seguintes alimentos',
		semaforoTextB: 'Água, Água aromatizada com frutas cítricas ou ervas, frutas, vegetais, legumes, grãos inteiros (pão integral, aveia, arroz integral, grãos inteiros para o café da manhã, etc.), nozes (em porções sem adição de açúcar ou sal), açúcar sem laticínios (iogurte e leite), Quesillo, queijo chacra, queijo fresco, nozes (em porções sem adição de açúcar ou sal), barras de cereais sem açúcar, infusões de ervas, ovos, peixe, carne vermelha magra, frango, peru, chia, linhaça, abacate , geléias sem açúcar.',
		createdBy: 'Enviado por',
		evaluationCreatedByYou: 'Avaliação criada por você',
		myChildUserMessage: 'Você é a conta-mãe deste usuário',
		acceptedTerms: 'Acepto términos y condiciones',
		ageUnder13: 'O usuário selecionado tem menos de 13 anos, corrija-o antes de continuar com a avaliação.',
		ageUnder13Button: 'Modificar usuário',
		deleteAskToConfirm: 'Você está prestes a excluir um item. Tem certeza?.',
		confirmBirthDataIsOkRegister: 'Sua idade é 13 anos. Esta informação está correta?. Confirme para enviar o formulário.',
		selection: 'Selecione',
		mantainVolume: 'Manter o volume',
		reduceVolume: 'Reduzir o volume',
		addVolume: 'Aumentar o volume',
		'Hipertensión': 'Hipertensão',
		'Hipercolesteremia': 'Hipercolesterolemia',
		'Diabetes': 'Diabetes',
		'Obesidad': 'Obesidade',
		'Hígado Graso': 'Fígado gordo',
		'Lupus': 'Lúpus',
		'Celíaca': 'Celíaca',
		'Cáncer': 'Câncer',
		'VIH': 'HIV',
		'Artritis': 'Artrite',
	}
};
let locale = localStorage.getItem('lang')
if(window.location.href.includes('register-pacific')){
	locale = 'es'
}

export const i18n = new VueI18n({
	locale,
	fallbackLocale: 'es',
	messages,
});